<template>
  <div id="app">
    <template v-if="vuex_token">
      <router-view></router-view>
      <van-tabbar v-if="false" :value="tabbar_index" safe-area-inset-bottom>
        <van-tabbar-item v-for="(item, index) in tabbar" :key="index" :icon="item.icon" :to="item.path">{{ item.text
          }}</van-tabbar-item>
      </van-tabbar>
    </template>
    <van-empty v-else description="系统繁忙，请重新进入小程序" />
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      tabbar: [
        {
          text: '创作',
          icon: 'home-o',
          path: '/editor/index'
        },
        {
          text: '我的',
          icon: 'user-o',
          path: '/user/index'
        }
      ]
    }
  },
  computed: {
    tabbar_index() {
      for (let i = 0; i < this.tabbar.length; i++) {
        if (this.$route.path.indexOf(this.tabbar[i].path) === 0) {
          return i
        }
      }
      return this.$route.path == '/' ? 0 : -1
    }
  },
  mounted() {
    this.update()
  },
  methods: {
    async update() {
      this.$vuex('vuex_user', (await this.$http.post('/user/get_info')).user)
      this.$vuex('vuex_materials', await this.$http.post('/n/materials'))
    }
  }
}
</script>

<style>
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';
  /* Project id 4707205 */
  src: url('//at.alicdn.com/t/c/font_4707205_8k2nezxp76x.woff2?t=1729318688180') format('woff2'),
    url('//at.alicdn.com/t/c/font_4707205_8k2nezxp76x.woff?t=1729318688180') format('woff'),
    url('//at.alicdn.com/t/c/font_4707205_8k2nezxp76x.ttf?t=1729318688180') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow: hidden;
  height: 100%;
  touch-action: none;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
  overscroll-behavior: none;
}
</style>
