import { mapState } from 'vuex'
import store from "@/store"

export default {
	beforeCreate() {
		this.$vuex = (name, value) => this.$store.commit('mStore', { name, value })
	},
	computed: {
		...mapState(Object.keys(store.state))
	}
}