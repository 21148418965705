import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import http from '@/libs/http'


Vue.use(Router);

const routes = [
  {
    path: '/',
    component: () => import('@/pages/editor/index.vue'),
    meta: { title: '漫剪剪' }
  },
  {
    path: '/editor/index',
    redirect: '/',
    meta: { title: '漫剪剪' }
  },
  {
    path: '/editor/editor',
    component: () => import('@/pages/editor/editor.vue'),
    meta: { title: '漫剪剪' }
  },
  {
    path: '/user/activation',
    component: () => import('@/pages/user/activation.vue'),
    meta: { title: '漫剪剪' }
  },
  {
    path: '/user/open_activation',
    component: () => import('@/pages/user/open_activation.vue'),
    meta: { title: '漫剪剪' }
  },
  {
    path: '/user/index',
    component: () => import('@/pages/user/index.vue'),
    meta: { title: '我的' }
  }
];

const router = new Router({
  mode: 'hash',
  routes
});

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title
  if (to.query.token) {
    store.commit('mStore', { name: 'vuex_token', value: to.query.token })
  }
  if (store.state.vuex_user === null) {
    store.commit('mStore', { name: 'vuex_user', value: (await http.post('/user/get_info')).user })
  }
  if (store.state.vuex_materials === null) {
    store.commit('mStore', { name: 'vuex_materials', value: await http.post('/n/materials') })
  }
  next()
})

export default router;
