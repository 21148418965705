import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

let data = JSON.parse(localStorage.getItem('data')) || {}

let save_keys = ['vuex_token', 'vuex_user', 'vuex_materials', 'vuex_editor_settings', 'vuex_render_count']

const saveData = function (key, value) {
	if (save_keys.indexOf(key) > -1) {
		let tmp = JSON.parse(localStorage.getItem('data')) || {}
		tmp[key] = value;
		localStorage.setItem('data', JSON.stringify(tmp));
	}
}
const store = new Vuex.Store({
	state: {
		vuex_token: data.vuex_token || '',
		vuex_user: data.vuex_user || null,
		vuex_logging: false,
		vuex_materials: data.vuex_materials || null,
		vuex_editor_settings: data.vuex_editor_settings || {
			auto_goto_keyframe: false,
			auto_cancel_multiple_select_enable: true
		},
		vuex_render_count: data.vuex_render_count || 0
	},
	mutations: {
		mStore(state, payload) {
			let name_arr = payload.name.split('.');
			let key = '';
			let len = name_arr.length;
			if (len >= 2) {
				let obj = state[name_arr[0]];
				for (let i = 1; i < len - 1; i++) {
					obj = obj[name_arr[i]];
				}
				obj[name_arr[len - 1]] = payload.value;
				key = name_arr[0];
			} else {
				state[payload.name] = payload.value;
				key = payload.name;
			}
			saveData(key, state[key])
		}
	}
})

export default store
