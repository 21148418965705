import('@/libs/fix')
import Vue from 'vue'
import App from './App.vue'

//windicss
import 'windi.css'

//app_config
import app_config from '@/config.json'
Vue.prototype.$app_config = app_config

//vant
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

//router
import router from '@/router'

//http
import http from '@/libs/http'
Vue.prototype.$http = http

//util
import util from '@/libs/util.js'
Vue.prototype.$util = util

//jtk
import jtk from '@/libs/jtk.js'
Vue.prototype.$jtk = jtk

//toast
import { Toast } from 'vant'
Toast.allowMultiple()
Toast.setDefaultOptions('loading', { forbidClick: true, duration: 0 });
Vue.prototype.$toast = Toast

//dialog
import { Dialog } from 'vant'
Vue.prototype.$dialog = Dialog

//store
import store from './store'
import vuexMixin from './store/mixin';
Vue.mixin(vuexMixin)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
