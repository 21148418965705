export default {
	mmss: (milliseconds) => {
		let seconds = Math.floor(milliseconds / 1000);
		let minutes = Math.floor(seconds / 60);
		seconds = seconds % 60;
		return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
	},
	loadImage: (url) => {
		return new Promise(resolve => {
			const img = new Image()
			img.crossOrigin = 'Anonymous';
			img.onload = () => {
				resolve(img)
			}
			img.src = url
		})
	},
	resizeImage: (imgWidth, imgHeight, maxWidth, maxHeight) => {

		// 计算图片的宽高比
		const aspectRatio = imgWidth / imgHeight;

		// 根据长边适应的原则，计算新的宽高
		let newWidth, newHeight;

		if (imgWidth > imgHeight) {
			// 如果宽度大于高度，根据 maxWidth 缩放宽度
			newWidth = maxWidth;
			newHeight = maxWidth / aspectRatio;
		} else {
			// 如果高度大于宽度，根据 maxHeight 缩放高度
			newHeight = maxHeight;
			newWidth = maxHeight * aspectRatio;
		}

		// 如果新的高度仍然超过了最大高度，重新调整
		if (newHeight > maxHeight) {
			newHeight = maxHeight;
			newWidth = maxHeight * aspectRatio;
		}

		// 如果新的宽度仍然超过了最大宽度，重新调整
		if (newWidth > maxWidth) {
			newWidth = maxWidth;
			newHeight = maxWidth / aspectRatio;
		}
		return {
			width: newWidth,
			height: newHeight
		}
	},

	/**
	 * 随机数
	 * @param {*} min 
	 * @param {*} max 
	 * @returns 
	 */
	rand: (min, max) => {
		return Math.floor(Math.random() * (max - min + 1)) + min;
	},

	/**
	 * 加载模板中的图片资源
	 * @param {*} template 
	 * @param {*} cb 
	 * @returns 
	 */
	loadTempateImages(template, scale, cb = null) {
		return new Promise(async (resolve) => {
			let frames = []
			const { objs, subtitles } = template
			for (const obj of objs) {
				frames = frames.concat(obj.material.frames)
				if (obj.material.type == 'head') {
					frames = frames.concat(obj.expression.frames)
				}
			}
			for (const subtitle of subtitles) {
				for (const obj of subtitle.objs) {
					if (obj.material) {
						frames = frames.concat(obj.material.frames)
					}
					if (obj.expression) {
						frames = frames.concat(obj.expression.frames)
					}
				}
			}
			for (let i = 0; i < frames.length; i++) {
				frames[i].image = await this.loadImage(frames[i].url + `?x-oss-process=image/resize,p_${parseInt(scale * 2 * 100)}`)
				if (cb) {
					cb(Math.floor((i + 1) / frames.length * 100))
				}
			}
			resolve()
		})
	},

	/**
	 * base64ToFile
	 * @param {*} base64Url 
	 * @returns 
	 */
	base64ToFile(base64Url) {
		// base64 格式: data:[<mime-type>];base64,<data>
		let arr = base64Url.split(',');
		let mime = arr[0].match(/:(.*?);/)[1]; // 提取 mime 类型
		let bstr = atob(arr[1]); // 将 Base64 解码
		let n = bstr.length;
		let u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n); // 将字符串转换为字节
		}

		// 根据 mime 类型生成文件扩展名
		let extension = 'bin'
		switch (mime) {
			case 'image/jpeg':
				extension = 'jpg';
				break
			case 'image/png':
				extension = 'png';
				break
			case 'image/gif':
				extension = 'gif';
				break
			case 'application/pdf':
				extension = 'pdf';
				break
			case 'text/plain':
				extension = 'txt';
				break
			default:
				extension = 'bin'; // 未知类型，返回默认扩展名
		}

		// 自动生成文件名
		let filename = `file_${Date.now()}.${extension}`;

		// 创建并返回 File 对象
		return new File([u8arr], filename, { type: mime });
	},

	/**
	 * 获取非透明区域
	 * @param {*} image 
	 * @returns 
	 */
	getNonTransparentBoundingBox(image) {
		const canvas = document.createElement('canvas')
		const ctx = canvas.getContext('2d')
		canvas.width = image.width
		canvas.height = image.height
		ctx.drawImage(image, 0, 0)

		let minX = image.width
		let minY = image.height
		let maxX = 0;
		let maxY = 0;

		const imageData = ctx.getImageData(0, 0, image.width, image.height);
		const data = imageData.data;

		for (let y = 0; y < image.height; y++) {
			for (let x = 0; x < image.width; x++) {
				const index = (y * image.width + x) * 4;
				if (data[index + 3] > 0) { // 检查透明度通道是否为非透明
					minX = Math.min(minX, x);
					minY = Math.min(minY, y);
					maxX = Math.max(maxX, x);
					maxY = Math.max(maxY, y);
				}
			}
		}

		return {
			x: minX,
			y: minY,
			width: maxX - minX,
			height: maxY - minY
		}
	}
}